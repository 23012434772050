import { buildComponents, AssetLoader } from '@alilc/lowcode-utils';


export interface IPackage {
  package: string;
  version?: string;
  urls: string[] | string;
  library: string;
  renderUrls?: string[] | string;
}

const resolvePackages = (packages: IPackage[]) => {
  const libraryMap: { [key: string]: string } = {};
  const libraryUrls: string[] = [];
  packages.forEach(({ package: packageName, library: libraryName, version, urls, renderUrls }) => {
    libraryMap[packageName] = libraryName;
    if (renderUrls) {
      if (Array.isArray(renderUrls)) {
        libraryUrls.push(...renderUrls);
      } else {
        libraryUrls.push(renderUrls);
      }
    } else if (urls) {
      if (Array.isArray(urls)) {
        libraryUrls.push(...urls);
      } else {
        libraryUrls.push(urls);
      }
    }
  });
  return {
    libraryMap,
    libraryUrls,
  };
};


/**
 * load packages with library cache
 */
export const loadComponents = async (packages: IPackage[], componentsMap: any[]) => {
  if (!componentsMap) return undefined;
  const { libraryMap, libraryUrls } = resolvePackages(packages);

  const assetLoader = new AssetLoader();
  await assetLoader.load(libraryUrls).catch(console.error);

  return buildComponents(
    libraryMap,
    componentsMap.reduce((map, component) => {
      map[component.componentName] = component;
      return map;
    }, {}),
    () => null,
  );
};
