import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

let root: ReactDOM.Root | undefined

const render = () => {
  root = ReactDOM.createRoot(
    document.getElementById('app') as HTMLElement
  );
  root.render(
    <App />
  );
}

const unmountApp = () => {
  root?.unmount()
  root = undefined
}

// standalone模式下运行
declare global {
  interface Window {
    __POWERED_BY_QIANKUN__: any
  }
}

if (!window.__POWERED_BY_QIANKUN__) {
  console.log('render in standalone mode')
  render();
}

export async function bootstrap() {
  console.log('[qiankun]: bootstrap');
}

export async function mount(props) {
  console.log('[qiankun]: mount, props', props);
  render();
}

export async function unmount() {
  console.log('[qiankun]: unmount,');
  unmountApp()
}