import { FC, useEffect, useState } from 'react';
import ReactRenderer from '@alilc/lowcode-react-renderer';
import { loadComponents } from './util';
import fetchHandler from '@redhare/datasource-fetch-handler';

interface IPageProps {
  schema: any;
  packages: any;
}

interface IPageData {
  pageSchema?: any,
  components?: any
}

const Page: FC<IPageProps> = (props) => {
  const { schema, packages } = props
  const [data, setData] = useState<IPageData>()

  async function init() {
    const { componentsMap, componentsTree } = schema;
    const pageSchema = componentsTree?.[0];
    const components = await loadComponents(packages, componentsMap);
    setData({ pageSchema, components })
  }

  useEffect(() => {
    init();
  }, []);

  if (!data) return

  const { pageSchema, components } = data;
  if (!pageSchema || !components) {
    return <div>Page is empty</div>
  }

  return (
    <ReactRenderer
      schema={pageSchema}
      components={components}
      appHelper={{
        requestHandlersMap: { fetch: fetchHandler }
      }}
    />
  );
};


export default Page;
