import Page from './Page';
import React from 'react'
import ReactDOM from 'react-dom'

window.React = React
window.ReactDOM = ReactDOM

declare global {
  interface Window {
    schema: any
    packages: any
  }
}

function App() {
  return (
    <Page schema={window.schema} packages={window.packages} />
  );
}

export default App;
